import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-welcome-back',
  templateUrl: './welcome-back.page.html',
  styleUrls: ['./welcome-back.page.scss'],
})
export class WelcomeBackPage implements OnInit {

  constructor(
    private router:Router,
    private modalController:ModalController
  ) { }

  ngOnInit() {
  }
  vision(){
   // console.log("gh")
    this.router.navigate(['/tabs/back-list'])
    this.modalController.dismiss({dismissed: true,});
  }
  values(){
    this.modalController.dismiss({dismissed: true,});
    //this.UserService.userCoreValues()
  }
}
