import { UserService } from "./../../../../services/user.service";
import { Component, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { NavParams } from "@ionic/angular";
import { GalleryPage } from "../gallery/gallery.page";
import { VideoPlayerPage } from 'src/app/modal/video-player/video-player.page';
import { UserDataService } from 'src/app/services/user-data.service';
@Component({
  selector: "app-core-visual",
  templateUrl: "./core-visual.page.html",
  styleUrls: ["./core-visual.page.scss"],
})
export class CoreVisualPage implements OnInit {
  corevalueID: any = "";
  userId: any = "";
  corevalue: any;
  type: any = "";
  file: any;
  name: any;
  image: any = {
    image: {},
  };
  settings:any;
  imageUpload: boolean = false;
  constructor(
    private modalController: ModalController,
    private navParams: NavParams,
    private UserService: UserService,
    private userData:UserDataService
  ) {}

  ngOnInit() {
    this.corevalueID = this.navParams.get("id");
    this.type = this.navParams.get("type");
     this.userId = this.userData.userInfo.uid
    this.UserService.getSettings().subscribe(res=>{
      this.settings = res
    })
    this.UserService.getToUpdate(
      this.corevalueID,
      this.type
    ).subscribe((res) => {
      // console.log(res)
      this.corevalue = res;
      // console.log(this.corevalue)
    });
  }
  async onFileInput(event) {
    if (event.target.files[0]) {
      const reader = new FileReader();
      this.file = event.target.files[0];

      this.name = this.file.name;

      reader.onload = this.handleReaderLoaded.bind(this);
      reader.readAsBinaryString(event.target.files[0]);
      this.imageUpload = true
    }
  }
  handleReaderLoaded(e) {
    (this.image.image.objectType = this.corevalueID),
      (this.image.image.objectId = this.userId);
    (this.image.image.imageEncoded =
      "data:image/png;base64," + btoa(e.target.result)),
      (this.corevalue.visionImage =
        "data:image/png;base64," + btoa(e.target.result)); // for displying the image

    this.image.image.imagename = this.name.split(".").slice(0, -1).join(".");

    // let formData = new FormData();
    // formData.append("objectType", this.corevalueID);
    //  formData.append("objectId", this.userId);
    //  formData.append("imageEncoded", 'data:image/png;base64,' + btoa(e.target.result));
    // console.log(image)
  }
  dismiss() {
    this.modalController.dismiss({
      dismissed: true,
    });
  }

  async presentModal() {
    const modal = await this.modalController.create({
      component: GalleryPage,
      componentProps: { id: this.corevalueID },
    });

    modal.onDidDismiss().then((dataAdded) => {
      console.log(dataAdded);
      if (dataAdded.data.flag && dataAdded.data.image != "") {
        this.corevalue.visionImage = dataAdded.data.image;
        this.imageUpload = false;
      }
    });
    return await modal.present();
  }

  update() {
    if (this.imageUpload) {
      this.UserService.uploadImage(this.image).subscribe(
        (res) => {
          this.corevalue.visionImage = res["url"];
          this.updateData();
        },
        (error) => {
          console.log(error);
        }
      );
    } else {
      this.updateData();
    }
  }
  updateData() {
    
    this.UserService.updateCoreValue(
      this.corevalueID,
      this.corevalue,
      this.type
    ).then((res) => {
      this.dismiss();
    });
  }
  async showVideo(msg){
   
    //Use Modal Controller to open VideoPlayer Page
    //Pass in the URL for the video that needs to played.
    let video = (this.type ==='coreValues') ? 'vision-core-values' : 'video-vision'
    const vplayer = await this.modalController.create(
      {component: VideoPlayerPage,
      componentProps:{videoURL:this.settings[video]}})
    vplayer.present();
  }
}
