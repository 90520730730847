import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';
import { AdvertComponent } from './advert/advert.component';
import { QdmkTabComponent } from './qdmk-tab/qdmk-tab.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
  
  ],
  declarations: [AdvertComponent, QdmkTabComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [AdvertComponent, QdmkTabComponent]
})
export class ComponentsModule {}
