import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

import { Observable } from 'rxjs';
import { map } from "rxjs/operators";

import * as firebase from 'firebase';
import { Router, CanActivate } from '@angular/router';
import { UserDataService } from './user-data.service';
import { AlertController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class LoginService implements CanActivate {


	//logindata: any = JSON.parse(window.localStorage.getItem('logindata')) 
	isBuildComplete: boolean 

  constructor(
    private router :Router,
    private userData: UserDataService,
    private alertCtrl: AlertController
  ) {
  }

  canActivate(): boolean {
    if (!this.userData.isLoggedIn) {
      this.router.navigate(['login']);
      return false;
    }
   
    return true;
  }
  resetPassword(email:string) {
    console.log(email)
   firebase.auth().sendPasswordResetEmail(email).then( async()=>{
     let alert = await this.alertCtrl.create({message:"Reset link set to your email."});
     alert.present()
   })
  }
}
