import { Component, OnInit } from '@angular/core';
import { ActionSheetController } from '@ionic/angular';
import { Router } from '@angular/router';
import { Storage } from '@ionic/storage';
import { UserDataService } from 'src/app/services/user-data.service';

@Component({
  selector: 'app-qdmk-tab',
  templateUrl: './qdmk-tab.component.html',
  styleUrls: ['./qdmk-tab.component.scss'],
})
export class QdmkTabComponent implements OnInit {

  constructor(private actionSheetController: ActionSheetController,
     private storage: Storage,
     private userData: UserDataService,
     private route: Router) { }

  ngOnInit() {}
go2(pg: string){
  this.route.navigate([pg]) 
}
async presentActionSheet() {
  const actionSheet = await this.actionSheetController.create({
    header: 'Menu',
    cssClass: 'my-custom-class',
    buttons: [{
      text: 'About',
      handler: () => {
        this.route.navigate(["tabs","about"])
      }
    }, {
      text: 'Share',
      handler: () => {
        this.route.navigate(["tabs","share"])
      }
    }, {
      text: 'Messages',
      handler: () => {
        this.route.navigate(["tabs","messages"])
      }
    }, {
      text: 'Logout',
      handler: () => {
       
        this.logout();
      }
    }, {
      text: 'Cancel',
    
      role: 'cancel',
      handler: () => {
      
      }
    }]
  });
  await actionSheet.present();
}
logout() {
  this.storage.remove("user");
  this.userData.isLoggedIn = false;
  this.route.navigate(["/login"]);
}

}
