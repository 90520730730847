import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.page.html',
  styleUrls: ['./video-player.page.scss'],
})
export class VideoPlayerPage implements OnInit {
  videoURL:any
  constructor(private modal: ModalController, private navParams: NavParams, private dsant: DomSanitizer) { }

  ngOnInit() {
   // this.videoURL = this.dsant.bypassSecurityTrustResourceUrl("https://player.vimeo.com/video/135629456")
   
   this.videoURL= this.dsant.bypassSecurityTrustResourceUrl( this.navParams.get("videoURL") )
  }
  dismiss(){
    this.modal.dismiss()
  }

}
