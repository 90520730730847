import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { WalkThruPageRoutingModule } from './walk-thru-routing.module';

import { WalkThruPage } from './walk-thru.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    WalkThruPageRoutingModule
  ],
  declarations: [WalkThruPage]
})
export class WalkThruPageModule {}
