import { Component, OnInit } from '@angular/core';
import { UserDataService } from '../services/user-data.service';
import { LoginService } from '../services/login.service';
import { UserService } from '../services/user.service';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-walk-thru',
  templateUrl: './walk-thru.page.html',
  styleUrls: ['./walk-thru.page.scss'],
})
export class WalkThruPage implements OnInit {
  next:boolean=false
  constructor(public userData:UserDataService,
    private userService: UserService,
    private LoginService: LoginService,
    public modalController: ModalController
    ) { }

  ngOnInit() {
  }
  nextScreen(){
    this.next = !this.next

  }
  dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss({
      dismissed: true,
    });
  }

}
