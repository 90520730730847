import { Component, OnInit } from '@angular/core';
import { NavParams, ModalController } from '@ionic/angular';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.page.html',
  styleUrls: ['./gallery.page.scss'],
})
export class GalleryPage implements OnInit {
  corevalueID:any=''
  images:any=[]
  selectedImages=""
  constructor(private navParams: NavParams,
    private modalController :ModalController,
    private UserService:UserService) { }

  ngOnInit() {
    this.corevalueID = this.navParams.get('id');
    console.log(this.corevalueID)
    this.getGalleryImages()
  }
  getGalleryImages(){
    this.UserService.galleryImages(this.corevalueID).subscribe(res=>{
      this.images = res["images"]
      console.log(this.images)
    })
  }
  select(img){
    this.selectedImages = img
  }
  dismiss(flag) {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss({flag:flag,image:this.selectedImages})
    // this.modalController.dismiss({
    //   'dismissed': true
    // });
  }

}
