import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  
  
  {
    path: 'walk-thru',
    loadChildren: () => import('./walk-thru/walk-thru.module').then( m => m.WalkThruPageModule)
  },
  {
    path:'login',
    loadChildren: ()=>import('./login/login.module').then(m=>m.LoginPageModule)
  },
  {
    path:'sign-up',
    loadChildren: ()=>import('./sign-up/sign-up.module').then(m=>m.SignUpPageModule)
  },
  {
    path: 'build',
    loadChildren: () => import('./pages/core-values/build/build.module').then( m => m.BuildPageModule)
  },
  {
    path: 'messages',
    loadChildren: () => import('./pages/messages/messages.module').then( m => m.MessagesPageModule)
  },
  
  {
    path: 'message/:id',
    loadChildren: () => import('./pages/msg-detail/msg-detail.module').then( m => m.MsgDetailPageModule)
  },
  {
    path: 'gallery',
    loadChildren: () => import('./pages/core-values/components/gallery/gallery.module').then( m => m.GalleryPageModule)
  },
  {
    path: 'share',
    loadChildren: () => import('./pages/core-values/components/gallery/gallery.module').then( m => m.GalleryPageModule)
  },
  {
    path: 'about',
    loadChildren: () => import('./pages/about/about.module').then( m => m.AboutPageModule)
  },
  {
    path: 'video-player',
    loadChildren: () => import('./modal/video-player/video-player.module').then( m => m.VideoPlayerPageModule)
  },
  {
    path: 'welcome-back',
    loadChildren: () => import('./modal/welcome-back/welcome-back.module').then( m => m.WelcomeBackPageModule)
  },
  
  {
    path: 'progress',
    loadChildren: () => import('./pages/progress/progress.module').then( m => m.ProgressPageModule)
  },
  {
    path: 'verify',
    loadChildren: () => import('./verify/verify.module').then( m => m.VerifyPageModule)
  },
  {
    path:'tabs',
    pathMatch: 'prefix',
    loadChildren:() => import('./folder/folder.module').then( m => m.FolderPageModule),
  },
  {
    path: '',
    redirectTo:'login',
    pathMatch:'full'
    //loadChildren: () => import('./folder/folder.module').then( m => m.FolderPageModule),
  },
  {
    path: '**',
    redirectTo: 'login',
    
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
