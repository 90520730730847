import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { AngularFireAuth } from '@angular/fire/auth';
import { first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserDataService {
public isLoggedIn:boolean=false
public userInfo:any={uid:''}
  constructor(public storage:Storage, private auth: AngularFireAuth) { }
  public  initFromStorage(){
    this.storage.get('user').then(async(val) => {
     
      if(val){
        this.isLoggedIn = true
        this.userInfo = val
        //Need to Check to make sure the user is exactly valid.
        let ex = await this.auth.authState.pipe(first()).toPromise();
        if(!ex){
          this.isLoggedIn = false;
          //Remove Storage cuz this isn't right anymore
          await this.storage.clear()
        }
      }
      else{
        this.isLoggedIn = false
      }
      let initComplete = new Event("initComplete")
          window.dispatchEvent(initComplete)
     
    });
  }
}
