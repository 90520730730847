import { Component, OnInit } from '@angular/core';
import {  IonSlides } from '@ionic/angular';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-advert',
  templateUrl: './advert.component.html',
  styleUrls: ['./advert.component.scss'],
})
export class AdvertComponent implements OnInit {
 slideOpts:any;
 adverts:any;
  constructor(private userService: UserService ) {
    this.slideOpts = {
      initialSlide: 1,
      speed: 5900,
    };
   }

  ngOnInit() {
    this.userService.getSettings().subscribe( (res:any) =>{
     
      this.adverts = res.ads
    })

  }
  slidesDidLoad(slides: IonSlides) {
    slides.startAutoplay();
  }
}
