import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from "@angular/fire/auth";
import { HttpClient } from '@angular/common/http';
import { UserDataService } from './user-data.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
userId:any='';
public settings;
  constructor(private db: AngularFirestore,
    private http: HttpClient,
    private auth: AngularFireAuth,
    private userData:UserDataService
  ) {
    this.userId = this.userData.userInfo.uid
    this.getSettings().subscribe(res=>{
      this.settings = res;
    })
   
   }

  signinUser(email: string, password: string) {
    return new Promise(async (resolve, reject) => {
      this.auth.auth.signInWithEmailAndPassword(email, password).then((res) => {
        if (res.user.uid) {
          this.db.doc(`users/${res.user.uid}`).snapshotChanges().subscribe((res) => {
            
            let emailVerified = this.auth.auth.currentUser.emailVerified
            res = Object.assign(res,{emailVerified})
            resolve(res);
          }, (err) => {
            console.log('error');
            reject(err);
          });
        }
        else {
          reject('Invalid Login');
        }
      }).catch((err) => {
        reject(err.message);
      })
    });
  }

  // signUpUser(data: any) {
  //   return this.db.collection(`users`).doc(data.firstName).ref.set(data)
  // }

  async signUpUser(data: any) {
    return new Promise((resolve, reject) => {
      let { email, password } = data;
      this.auth.auth.createUserWithEmailAndPassword(email, password).then(async (res: any) => {
        data.uid = res.user.uid;
        delete data.password;
        if (res.user && res.user.uid) {
          await this.db.collection(`users`).doc(res.user.uid).ref.set(data);
          res.user.sendEmailVerification()
          resolve(true);
        } else {
          reject("Unable to signup");
        }
      }).catch((err) => {
        reject(err.message);
      });
    });

  }

  getUserCoreValueCss() {
    return this.db.doc(`users/${this.userId}`).snapshotChanges()
  }
  getUser() {
    return this.db.doc(`users/${this.userId}`).valueChanges()
  }
  getSettings() {
    return this.db.doc(`settings/app`).valueChanges()
  }
  userCoreValues() {
    this.userId = this.userData.userInfo.uid
    return this.db.collection(`users/${this.userId}/coreValues`).snapshotChanges()
  }
  getToUpdate(corevalueId, type) {
    return this.db.doc(`users/${this.userId}/${type}/${corevalueId}`).valueChanges()
  }
  updateCoreValue(segment, data, type) {
    
    return this.db.doc(`users/${this.userId}/${type}/${segment}`).update(data)
  }
  userVisionValues() {
    return this.db.collection(`users/${this.userId}/visionValues`).snapshotChanges()
  }
  insertVisionValues( data) {
    data.forEach((element, index) => {
      // console.log(data[index])

      this.db.collection(`users/${this.userId}/visionValues`).doc(element.key).set(element.object)


    });

  }
  updateUserPhoto(profilePhoto) {
    
   
    return this.db.collection("users").doc(this.userId).update({profilePhoto})
  }
  getCoreValues() {
    return this.db.collection("segments").snapshotChanges()
  }
  getMessage() {
    return this.db.collection("messages").snapshotChanges()
  }
  getMessag(id) {
    return this.db.doc(`messages/${id}`).valueChanges()
  }
   updateUserCoreValueCss(type, color) {
    var cssUpdate = {};
    cssUpdate[`coreValueCSS.${type}`] = color;
    return this.db.collection("users").doc(this.userId).update(cssUpdate)
  }
  createCoreValues(key, value) {
    return this.db.collection(`users/${this.userId}/coreValues`).doc(key).set({ secondaryValues: value, coreValue: '', coreValuedef: '' })

  }
  updateHeaderText(text){
    var headerText = {};
    headerText[`coreValueHeaderText`] = text;
    return this.db.collection("users").doc(this.userId).update(headerText)
  }
  completeBuild() {
    var buildComplete = {};
    buildComplete[`isBuildComplete`] = true;
    return this.db.collection("users").doc(this.userId).update(buildComplete)
  }

  // get stock-images 

  galleryImages(type) {
    console.log(type)
    return this.db.doc(`stock-images/${type}`).valueChanges()
  }



  // Image Upload
  uploadImage(data) {
    console.log(data, "SENT TO API")
    let apiUrl = `https://us-central1-brandworx-firedb.cloudfunctions.net/uploadImage`
    //axios.post(apiUrl, data.image)
    return this.http.post(apiUrl, data.image)


  }
    // Send Email
    sendEmail(data) {
      console.log(data, "SENT TO API")
      let apiUrl = `https://us-central1-brandworx-firedb.cloudfunctions.net/sendEmail`
      //axios.post(apiUrl, data.image)
      return this.http.post(apiUrl, data)
  
  
    }
}
