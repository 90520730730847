import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuth, AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { environment } from '../environments/environment';
import { CoreVisualPage } from './pages/core-values/components/core-visual/core-visual.page';
import { SharePageModule } from './modal/share/share.module';
import { CoreVisualPageModule } from './pages/core-values/components/core-visual/core-visual.module';
import { HttpClientModule } from '@angular/common/http';
import { GalleryPage } from './pages/core-values/components/gallery/gallery.page';
import { GalleryPageModule } from './pages/core-values/components/gallery/gallery.module';
import { SocialSharing } from "@ionic-native/social-sharing/ngx";
import { IonicStorageModule } from '@ionic/storage';
import { WalkThruPage } from './walk-thru/walk-thru.page';
import { WalkThruPageModule } from './walk-thru/walk-thru.module';
import { VideoPlayerPageModule } from './modal/video-player/video-player.module';
import { CommonModule } from '@angular/common';
import { ProgressPage } from './pages/progress/progress.page';
import { ProgressPageModule } from './pages/progress/progress.module';
import { VideoPlayerPage } from './modal/video-player/video-player.page';
import { WelcomeBackPageModule } from './modal/welcome-back/welcome-back.module';
import { WelcomeBackPage } from './modal/welcome-back/welcome-back.page';



@NgModule({
  declarations: [AppComponent],
  entryComponents: [CoreVisualPage,GalleryPage,WalkThruPage,ProgressPage, VideoPlayerPage, WelcomeBackPage],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    SharePageModule,
    CoreVisualPageModule,
    ProgressPageModule,
    VideoPlayerPageModule,
    WelcomeBackPageModule,
    GalleryPageModule,
    WalkThruPageModule,
    VideoPlayerPageModule,
    CommonModule,
    HttpClientModule,
    IonicStorageModule.forRoot()


  ],
  providers: [
    AngularFirestore,
    AngularFireAuth,
    StatusBar,
    SplashScreen,
    SocialSharing,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
