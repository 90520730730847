import { Component, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { UserService } from "../../services/user.service";
import { UserDataService } from "src/app/services/user-data.service";

@Component({
  selector: "app-progress",
  templateUrl: "./progress.page.html",
  styleUrls: ["./progress.page.scss"],
})
export class ProgressPage implements OnInit {
  frontList;
  coreValuePercentage = 0;
  visionPercentage = 0;
  imageHeader;
  settings;
  usersName;
  userProfilePhoto;
  constructor(
    private modalCltr: ModalController,
    private UserService: UserService,
    private userData: UserDataService
  ) {}

  ngOnInit() {
    this.coreValuePercentageCal();
    this.getVisionPercentage();
    this.UserService.getSettings().subscribe((res) => {
      this.settings = res;
      this.imageHeader = res["progress-header-image"];
    });
    this.UserService.getUser().subscribe((usr) => {
      this.usersName = usr["firstName"];
      this.userProfilePhoto =
        usr["profilePhoto"] || "../../../assets/img/defaultProfile.png";
    });
  }
  dismiss() {
    this.modalCltr.dismiss();
  }
  getVisionPercentage() {
    this.UserService.userVisionValues().subscribe((res) => {
      let number = 0;
      res.map((a) => {
        const data: any = a.payload.doc.data();
        const id = a.payload.doc.id;

        if (data["visionValueDef"] != "") {
          number = number + 1;
        }
      });
      this.visionPercentage = (number / res.length) * 100;
    });
  }
  coreValuePercentageCal() {
    this.UserService.userCoreValues().subscribe((res) => {
      //  console.log(res)
      let number = 0;
      res.map((a) => {
        const data: any = a.payload.doc.data();
        const id = a.payload.doc.id;
        if (data["coreValuedef"] != "") {
          number = number + 1;
        }
      });
      this.coreValuePercentage = (number / res.length) * 100;

      console.log(this.coreValuePercentage);
    });
  }
  uploadProfilePhoto(photos) {
    let photo: File = photos.files[0];
    let myReader: FileReader = new FileReader();

    myReader.onloadend = (e) => {
      this.userProfilePhoto = myReader.result.toString();
      this.UserService.updateUserPhoto(this.userProfilePhoto);
    };
    myReader.readAsDataURL(photo);
  }
}
