// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
  	apiKey: "AIzaSyB0k1jcZJtRSe8okqT5GXpsl_pOjWE27VM",
    authDomain: "brandworx-firedb.firebaseapp.com",
    databaseURL: "https://brandworx-firedb.firebaseio.com",
    projectId: "brandworx-firedb",
    storageBucket: "brandworx-firedb.appspot.com",
    messagingSenderId: "1087471629220",
    appId: "1:1087471629220:web:fa13041fe25c0bf7fcc731",
    measurementId: "G-S64QJ8J0PN"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
