import { Component, OnInit } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Router } from '@angular/router';
import { Storage } from '@ionic/storage';

import { UserDataService } from './services/user-data.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent  {
  public selectedIndex = 0;
  public appPages = [
    {
      title: 'Inbox',
      url: '/folder/Inbox',
      icon: 'mail'
    },
    {
      title: 'Outbox',
      url: '/folder/Outbox',
      icon: 'paper-plane'
    },
    {
      title: 'Favorites',
      url: '/folder/Favorites',
      icon: 'heart'
    },
    {
      title: 'Archived',
      url: '/folder/Archived',
      icon: 'archive'
    },
    {
      title: 'Trash',
      url: '/folder/Trash',
      icon: 'trash'
    },
    {
      title: 'Spam',
      url: '/folder/Spam',
      icon: 'warning'
    }
  ];
  public labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private router:Router,
    public storage: Storage,
    public userData: UserDataService
  ) {
    
    this.initializeApp();
   // this.checkKeep();
  }

  initializeApp() {
    this.platform.ready().then(() => {
   
      this.userData.initFromStorage()
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      
      window.addEventListener("initComplete",(ans)=>{
        console.log("FINIT", this.userData.isLoggedIn)
        if (!this.userData.isLoggedIn){
        
          this.router.navigate(["/login"]) // navigate if not logged in  
        }
      })

      // OneSignal Code start:
    // Enable to debug issues:
    // window["plugins"].OneSignal.setLogLevel({logLevel: 4, visualLevel: 4});
    // Set your iOS Settings
    var iosSettings = {};
    iosSettings["kOSSettingsKeyAutoPrompt"] = false;
    iosSettings["kOSSettingsKeyInAppLaunchURL"] = false;

    var notificationOpenedCallback = function(jsonData) {
      console.log('notificationOpenedCallback: ' + JSON.stringify(jsonData));
    
    };
    

    window["plugins"]['OneSignal']
      .startInit("06387b21-331d-47f6-b4ce-054a33a040e5", "1087471629220")
      .handleNotificationOpened(notificationOpenedCallback)
      .iOSSettings(iosSettings)
      .endInit();
     });
  }

  ngOnInit() {
    // const path = window.location.pathname.split('folder/')[1];
    // if (path !== undefined) {
    //   this.selectedIndex = this.appPages.findIndex(page => page.title.toLowerCase() === path.toLowerCase());
    // }
  }

  checkKeep(){
    let keep  = localStorage.getItem('keep')
    if(keep){
      if(keep=='1'){
        this.router.navigate(['/tabs/front-list'])
      }
      else{
        localStorage.removeItem('logindata')
        localStorage.removeItem('memberId')
      }
    }
    else{
      localStorage.removeItem('logindata')
      localStorage.removeItem('memberId')
    }
  }

}
