import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { UserService } from 'src/app/services/user.service';
@Component({
  selector: 'app-share',
  templateUrl: './share.page.html',
  styleUrls: ['./share.page.scss'],
})
export class SharePage implements OnInit {
  cvPng:any;


  cardCV: string;
  cardVS: string;
  userId: string;
  settings: any;
  url: string;
  constructor(private socialSharing: SocialSharing, private userService: UserService) { }
 
  ngOnInit() {
   
   
    this.userService.getSettings().subscribe( (res:any)=>{
     this.settings = res
     this.url = this.settings["share-url"] || "https://myquickdecisionmaker.com"
    });

  }
  getItNow(){
    
  }
  share(platform:string){
    let message =  this.settings["share-text"]
    let imageSrc =  this.settings["share-photo"]
    switch(platform){
      
      case "":
        this.socialSharing.shareViaFacebook(message,imageSrc, this.url).then( v =>{
          console.log(v)
        })
        break;
      case "IG":
        this.socialSharing.shareViaInstagram(message, this.cardCV).then( v =>{
          console.log(v)
        })
        break;
      case "TW":
        this.socialSharing.shareViaTwitter(message, imageSrc,this.url).then( v =>{
          console.log(v)
        })

    }
    
  }
  

}
